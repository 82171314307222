.privacy-choices-icon-link:hover,
.your-privacy-choices:hover {
  text-decoration: underline !important; }

.privacy-choices-icon {
  display: inline-block;
  width: 27px;
  height: 12px;
  margin: 0 3px 2px 0; }

.your-privacy-choices {
  display: inline-block;
  margin-right: .3em; }

.osano-cm-widget {
  display: none; }

.osano-cm-info-dialog {
  font-size: 14px; }

.osano-cm-description, .osano-cm-info p {
  font-size: 1em; }

.osano-cm-toggle__label.osano-cm-label {
  font-size: 1em !important;
  font-weight: 300; }
